import React, { useEffect, useState } from 'react'
import { Container, Box, Typography, Grid, Divider, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import playStoreImg from '../../assets/images/App Platform.png'
import appStoreImg from '../../assets/images/App Platform (1).png'
import zenRideBackgroundImg from '../../assets/images/backgroundfooter.png'
import Logo from 'src/@core/components/logo'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndpoints } from 'src/network/endpoints'
import toast from 'react-hot-toast'

const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0) // Scroll to the top when the component mounts
  }, [])
  const [loading, setLoading] = useState(false)
  const [footer, setFooter] = useState([])
  const getFooter = () => {
    setLoading(true)
    axiosInstance
      .get(`${ApiEndpoints.FOOTER.list}`, {})
      .then(response => {
        setFooter(response?.data?.data?.footerDetails || [])
      })
      .catch(error => {
        toast.error(error.response.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    getFooter()
  }, [])
  if (loading || footer.length === 0) return null

  const { linkedin, twitter, apple_app_store, google_play_store, instagram, facebook } = footer[0]
  return (
    <>
      <Box
        sx={{
          pt: { sm: 15 },
          backgroundImage: `url(${zenRideBackgroundImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right bottom'
        }}
      >
        <Container maxWidth='xl' sx={{ paddingX: { xs: '20px', sm: '30px', md: '50px' } }}>
          <Divider color='#474747' sx={{ my: 6 }} />
          <Grid
            container
            sx={{
              pt: {
                xs: '20px',
                md: '60px'
              }
            }}
          >
            <Grid item xs={12} sm={12} md={3}>
              <Box gap={2} mt={2}>
                <Typography
                  variant='fm-h3'
                  sx={{
                    fontSize: { xs: '18px', sm: '25px', md: '25px', lg: '35px', xl: '40px' },
                    fontWeight: 600,
                    lineHeight: { xs: '18px', md: '28px', lg: '38px', xl: '48px' }
                  }}
                  color={'#ffff'}
                >
                  Download our App
                </Typography>
              </Box>
              <Box gap={2} mt={4}>
                <Typography
                  variant='fm-h6'
                  sx={{
                    fontSize: { xs: '16px', lg: '20px' },
                    fontWeight: 500,
                    lineHeight: { md: '17px', lg: '27px' }
                  }}
                  color={'#9CE26E'}
                >
                  Available for android and iOS
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Link to='/about-us' style={{ textDecoration: 'none' }}>
                {' '}
                <Typography
                  sx={{
                    fontSize: { xs: '16px', md: '16px', lg: '20px' },
                    fontWeight: 500,
                    lineHeight: { md: '27px', lg: '27px' }
                  }}
                  color={'#AEAEAE'}
                >
                  Download Zanride mobile app to experience effortless travelling
                </Typography>
              </Link>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: {
                    xs: 'column',
                    xl: 'row'
                  },
                  alignItems: 'center',
                  gap: '20px'
                }}
                mt={7}
              >
                <Grid container display='flex' justifyContent='start' spacing={5}>
                  <Grid sx={{ maxWidth: '195px !important' }} item md={6}>
                    <Link to={google_play_store} style={{ textDecoration: 'none', width: '100%' }}>
                      {' '}
                      <Button
                        sx={{
                          width: '100%',
                          maxWidth: '175px',
                          p: '10px 24px 10px 16px',
                          color: '#ffff',
                          border: '1px solid #4F4F4F',
                          fontSize: { xs: '14px', lg: '18px' },
                          fontWeight: 600,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          backgroundColor: '#212121',
                          borderRadius: 0,
                          ':hover': { backgroundColor: '#ffff', color: 'black' }
                        }}
                      >
                        <img src={playStoreImg} alt='' />
                        Play Store
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item sx={{ maxWidth: '195px !important' }} md={6}>
                    <Link to={apple_app_store} style={{ textDecoration: 'none' }}>
                      <Button
                        sx={{
                          width: '100%',
                          maxWidth: '175px',
                          p: '10px 24px 10px 16px',
                          color: '#ffff',
                          border: '1px solid #4F4F4F',
                          fontSize: { xs: '14px', lg: '18px' },
                          fontWeight: 600,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          backgroundColor: '#212121',
                          borderRadius: 0,
                          ':hover': { backgroundColor: '#ffff', color: 'black' }
                        }}
                      >
                        <img src={appStoreImg} alt='' />
                        App Store
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Link style={{ textDecoration: 'none' }} to={'/sitemap'}>
                <Typography
                  fontWeight={'500'}
                  lineHeight={'16px'}
                  sx={{ fontSize: '16px', textAlign: { md: 'end' }, mt: { xs: 10, sm: 6 } }}
                >
                  Site Map
                </Typography>{' '}
              </Link>
              <Link style={{ textDecoration: 'none' }} to={'/become-a-driver'}>
                <Typography
                  fontWeight={'500'}
                  lineHeight={'16px'}
                  sx={{ fontSize: '16px', textAlign: { md: 'end' }, mt: { xs: 10, sm: 6 } }}
                >
                  Become a Driver
                </Typography>{' '}
              </Link>
              <Link style={{ textDecoration: 'none' }} to={'/blog'} target='_blank'>
                {' '}
                <Typography
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'16px'}
                  sx={{ fontSize: '16px', textAlign: { md: 'end' } }}
                  mt={6}
                >
                  Blogs
                </Typography>
              </Link>
              <Link style={{ textDecoration: 'none' }} to={'/contact'}>
                <Typography
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'16px'}
                  sx={{ fontSize: '16px', textAlign: { md: 'end' } }}
                  mt={6}
                >
                  Contact Us
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Link style={{ textDecoration: 'none' }} to={instagram} target='_blank'>
                {' '}
                <Typography
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'16px'}
                  sx={{ fontSize: '16px', textAlign: { md: 'end' } }}
                  mt={6}
                >
                  Instagram
                </Typography>
              </Link>
              <Link style={{ textDecoration: 'none' }} to={facebook} target='_blank'>
                <Typography
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'16px'}
                  sx={{ fontSize: '16px', textAlign: { md: 'end' } }}
                  mt={6}
                >
                  Facebook
                </Typography>
              </Link>
              <Link style={{ textDecoration: 'none' }} to={linkedin} target='_blank'>
                <Typography
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'16px'}
                  sx={{ fontSize: '16px', textAlign: { md: 'end' } }}
                  mt={6}
                >
                  Linkedin
                </Typography>
              </Link>
              <Link style={{ textDecoration: 'none' }} to={twitter} target='_blank'>
                <Typography
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'16px'}
                  sx={{ fontSize: '16px', textAlign: { md: 'end' } }}
                  mt={6}
                >
                  Twitter
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              paddingY: { xs: '10px', md: '35px' },
              paddingX: { md: '50px' }
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              sx={{
                paddingY: { xs: '10px', md: '0px' }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '40px' }}>
                <Link to='/terms-and-condition' style={{ textDecoration: 'none' }}>
                  {' '}
                  <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '16px', color: '#fff' }}>
                    Terms
                  </Typography>
                </Link>
                <Link to='/privacy-policy' style={{ textDecoration: 'none' }}>
                  {' '}
                  <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '16px', color: '#fff' }}>
                    Privacy
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              sx={{ display: 'flex', justifyContent: { md: 'center' }, paddingY: { xs: '10px', md: '0px' } }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '16px', color: '#656464' }}>
                ©2024 ZanRide
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              sx={{
                display: 'flex',
                justifyContent: { md: 'end' },
                paddingY: { xs: '10px', md: '0px' }
              }}
            >
              <Box mt={'10px'}>
                <Link to='/' sx={{ textDecoration: 'none' }}>
                  <Logo />
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default Footer
